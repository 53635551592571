@import "./Variables.scss";

.detailContainer {
    text-align: left;
    margin: 15px 0 5px 0;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .title {
        color: $blue;
        font-size: 1rem;
        font-weight: 400;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .MuiChip-root {
        margin-right: 8px;
        margin-top: 5px;
        font-size: 10px;
        height: 25px;
        line-height: 25px;

        @media (max-width: 767px) {
            margin-right: 4px;
            margin-top: 4px;
            font-size: 8px;
            height: 20px;
            line-height: 22px;
        }

        .MuiChip-label {
            line-height: 1;
        }
    }

    .reservation-item {
        width: 200px;
        flex: 0 0 200px;
        display: flex;
        align-items: center;
        margin-bottom: auto;
        margin-top: 0;

        @media (max-width: 767px) {
            width: 150px;
            flex: 0 0 150px;
        }
    }

    .reservationAction {
        display: flex;
        align-items: center;
        border-top: solid 1px rgba(0, 0, 0, 0.12);
        padding-top: 15px;
        margin-top: 20px;
        margin-bottom: -10px;

        h6 {
            margin: 0;
            color: #2A3F54;
            font-size: 14px;
            text-decoration: underline;
            margin-right: 30px;
            cursor: pointer;

            .icon {
                border-radius: 0;
                background-color: transparent;
                box-shadow: none;
            }
        }
    }

    h6 {
        color: rgba($blue, 0.7);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;

        @media (max-width: 767px) {
            // flex-wrap: wrap;
        }

        &.guest-count {
            @media (max-width: 767px) {
                flex-wrap: inherit;
            }

            .reservation-item {
                @media (max-width: 767px) {
                    width: auto;
                    flex: 0 0 auto;
                }
            }
        }

        i {
            color: rgba($blue, 0.7);
        }

        strong {
            // color: $blue;
            opacity: 0.8;
            font-weight: 600;
            margin-left: 4px;
            color: #2A3F54;
        }

        .icon {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 6px #0000001A;
            margin-right: 8px;
            float: left;

            svg {
                width: 0.875rem;
            }
        }
    }

    a {
        &:hover {
            text-decoration: none
        }

        span {
            font-size: 0.813rem;
        }

        svg {
            font-size: 1rem;
            margin-bottom: -3px;
        }
    }

    .modifyReservationLink {
        cursor: pointer;
        display: inline-block;
    }

    .chooseTimeSelect {
        width: 100%;

        svg {
            color: #aeb6bd
        }

        .MuiSelect-select {
            color: #333333;
        }
    }

    .reservation-item-header {
        flex-wrap: wrap;

        .reservation-item-subtitle {
            @media (max-width: 767px) {
                margin: -8px 0 0 38px !important;
            }
        }
    }
}

.MuiMenu-paper {
    max-height: 250px !important;
}

@media (max-width: 767px) {
    .infoContainer {
        h6 {
            font-size: 14px;
        }
    }
}