@import "./Variables.scss";

.cancelContainer {
    height: auto;
    background-color: $background-color;
    color: $black;
    margin-bottom: 4.7rem;

    .nextButtonContainer {
        padding: 8px !important;
    }

    .buttonFix {
        height: 55px;
    }
}