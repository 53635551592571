@font-face {
    font-family: 'PTSans-Regular';
    src: local('PTSans-Regular'), url('./assets/fonts/ptsans/PTSans-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'PTSans-Regular';
    src: local('PTSans-Regular'), url('./assets/fonts/ptsans/PTSans-Bold.ttf') format('ttf');
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
    font-family: 'PT Sans', sans-serif;
}

.appContainer {
    max-width: 690px !important;
    width: 100%;
    margin: 30px auto !important;
}

@media (max-width:767px){
    .widgetHeader {
        margin-top: 0px !important; 
    }

    .widgetHeader .makeReservation {
        border-radius: 0px !important;
    }
}
