@import "./Variables.scss";

.cancellationFailedContainer {
    padding: 4rem 0;

    svg {
        margin-bottom: 1.50rem;
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 700;
        padding-bottom: 0.50rem;
    }

    span,
    p {
        font-size: 1rem;
        display: block;
        padding-bottom: 0.250rem;
    }
}