.previewLineContainer {
    width: 100%;
    height: 35px;
    position: fixed;
    z-index: 3;
    background-color: #fff;
    border: 1px solid #EBEEF4;
    .previewLine {
        // width: 350px;
        margin: auto;
        height: 35px;
        // padding-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            float: left;
            color: #32bd58;
            &.MuiSvgIcon-colorDisabled {
                color: rgba(0, 0, 0, 0.26);
            }
        }

        .fixedPositionLeft {
            position: relative;
        }

        .fixedPositionRight {
            position: relative;
        }

        .previewLineText {
            margin: 4px 25px;
            float: left;
            font-size: 14px;
            color: #444;
            .previewLineLabel {
                text-transform: uppercase;
                font-weight: bold;
                float: left;
                margin-right: 5px;
            }
        }
    }
}