$header-background-color: #04c985;
$background-color: #f4f5f7;
$field-icon-color: #afb4bc;
$checkbox-label-color: #646565;
$button-background-color-primary: #00A0E1;
$button-color-primary: #fff;
$timer-background-color: #EA4C36;
$logo-background-color: #C8C8C8;
$link-color: #00A0E1;
$blue: #2A3F54;
$green: #32BD58;
$black: #000;
$white: #fff;
$grey: #CCC;
$red: #C1756D;
$darkGrey: #ADB5BD;