@import "./Variables.scss";

.guestReservationDetailContainer {
    .guestTitle {
        padding-top: 15px;
        padding-bottom: 5px;

        h2 {
            font-size: 30px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            font-weight: 700;
        }

        span {
            font-size: 18px;
            font-weight: 700;
        }
    }

    table {
        td {
            padding: 1px 0px;
            border: 0px;
            font-weight: 600;
            font-size: 14px;

            &:first-child {
                min-width: 115px;
                width: 115px;
                font-weight: normal;
            }
        }
    }

    .uppercase {
        text-transform: uppercase;
    }
}