@import "./Variables.scss";

.infoContainer {
    text-align: left;
    margin: 15px 0 5px 0;

    .title {
        color: $blue;
        font-size: 1rem;
        font-weight: 400;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    h6 {
        color: rgba($blue, 0.7);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;

        i {
            color: rgba($blue, 0.7);
        }

        strong {
            // color: $blue;
            // opacity: 0.8;
            font-weight: 600;
            margin-left: 4px;
            color: #2A3F54 !important;
            

            @media(max-width:767px) {
                margin-left: 10px;
                width: 100%;
               //text-align: right;
            }
        }

        .icon {
            width: 30px;
            height: 30px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 6px #0000001A;
            margin-right: 8px;
            float: left;

            svg {
                width: 1rem;
            }
        }
        & > div {            
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    a {
        &:hover {
            text-decoration: none
        }

        span {
            font-size: 0.813rem;
        }

        svg {
            font-size: 1rem;
            margin-bottom: -3px;
        }
    }

    .modifyReservationLink {
        cursor: pointer;
        display: inline-block;
    }
    .chooseTimeSelectContainer{
        padding-top: 12px;
    }

    .chooseTimeSelect {
        width: 105px;
        .error{
            fieldset{
                border-color: #f44336;
            }
        }
        .MuiInputBase-input{
            color: #333333;
            font-weight: bold;
            font-size: 14px;
        }

        @media (max-width: 767px) {
            margin-top: 0px;
          
        }

        .MuiInputBase-root {
            font-size: 14px;

            .MuiSelect-select {
                font-weight: bold;
            }

            @media (max-width: 767px) {
                text-align: left;
            }
        }

        .MuiOutlinedInput-adornedStart {
            @media (max-width: 767px) {
                padding-left: 10px;
            }
        }

        svg {
            color: #aeb6bd;
        }

        .MuiSelect-select {
            color: #333333;
        }
    }

    .reservation-item {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin-bottom: auto;
        margin-top: 0;
        width: 175px;
        color: #949EA9;

        @media (max-width: 767px) {
            width: 170px;
            flex: 0 0 170px;
            
        }
    }

    .reservation-item-header {
        flex-wrap: wrap;

        .reservation-item-subtitle {
            @media (max-width: 767px) {
                margin: -8px 0 0 38px !important;
            }
        }

        .reservation-item {
            width: 175px;
            flex: 0 0 175px;

            @media (max-width: 767px) {
                width: auto;
                flex: 0 0 auto;
            }
        }
    }
}

.MuiMenu-paper {
    max-height: 250px !important;

    li {
        font-size: 0.85rem;
    }
}

@media (max-width: 767px) {
    .infoContainer {
        h6 {
            font-size: 12px;
        }
    }
}

.icon-list-block {
    color: rgba(42, 63, 84, 0.7);
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;

    .reservation-item {
        width: 175px;
        flex: 0 0 175px;
        display: flex;
        align-items: center;
        margin-bottom: auto;
        margin-top: 0;

        @media (max-width: 767px) {
            width: 170px;
            white-space: nowrap;
            flex: 0 0 170px;
            margin: 0;
        }
    }
}

.icon-list-block-guest {
    @media (max-width: 767px) {
        margin-bottom: 10px !important;
    }
}

.separator-text {
    @media (max-width: 767px) {
        display: none;
    }
}

.date-time-container {
    @media (max-width: 767px) {
        margin-left: 7px;
        display: inherit;

        strong {
            margin: 0 !important;

            &:nth-child(n+2) {
                margin-left: 2px !important;
            }
        }
    }
}