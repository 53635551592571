@import "./Variables";

.logoContainer {
    display: inline-block;
    overflow: hidden;
    position: relative;
    min-height: 200px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;

    &.MuiGrid-root {
        align-items: flex-start;
        justify-content: flex-end;
        text-align: right;
        padding-right: 1.875rem;
    }

    .logo {
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 7px 7px;
        background: #fff;
        margin-left: auto;

        img {
            max-width: 100%;
            width: 48px;
        }
    }

    .background {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .eventName {
        color: #2A3F54, ;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .eventDate {
        color: #2A3F54;
        opacity: 0.7;
        font-size: 14px;
    }
}
.coverImage{
    background-size: cover;
}
.carouselSlideCount_1 .slider-control-centerleft, .carouselSlideCount_1 .slider-control-centerright{
    display: none;
}
@media all and (max-width:680px){
    .logoContainer{
        min-height: initial;
        height: auto !important;
        padding-bottom: 33.33%;
    }
}