@import "./Variables";

.phoneNumberError {
    .PhoneInput {
        border-color: #fe0412 !important;
        overflow: hidden;
    }
}

.PhoneInput {
    float: left;
    width: 100%;
    //box-shadow: 1px 1px 2px 1px #BBBCBE;
    border: 1px solid #BBBCBE;
    border-radius: 4px;
    background-color: white;

    input {
        font-family: inherit;
        padding: 0.4375rem 0px;
        border-width: 0px 0px 1px 0px;
        height: 1.8125rem;
        border-bottom: 0px;
        outline: 0;
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.87);
        background: transparent;
    }

    .PhoneInputCountry {
        margin-right: 0px !important;
        padding-right: 0.1875rem;
        padding-left: 0.625rem;
        background: transparent;

        .PhoneInputCountryIcon {
            font-size: 0.85rem;
            height: 1.5625rem;
            background-color: transparent;
            box-shadow: none;
            color: rgba(0, 0, 0, 0.87);

            svg {
                color: $field-icon-color;
            }

            &.PhoneInputCountryIcon--border {
                width: 100%;
            }
        }
    }

    .phoneInputDropdown {
        margin-left: 0.3125rem;
        margin-top: 0.25rem;

        .phoneInputDropdownImg {
            width: 1.4375rem;
            float: left;
            margin-right: 0.5rem;
            margin-top: 0.125rem;

            img {
                width: 100%;
                height: auto;
            }
        }

        .phoneInputDropdownCode {
            float: left;
        }
    }
}

.phoneNumberCodeLabel {
    flex-basis: unset !important;

    label {
        text-align: left;
        width: 3.125rem;
        color: #afafaf;
        font-size: 0.8125rem;
        font-weight: bold;
    }
}

.mobileNumberContainer {
    width: 100%;

    &:hover {
        .deletePhoneNumber {
            width: 2.1875rem;
            margin-left: -2.1875rem;

            svg {
                display: block;
            }
        }
    }

    .deletePhoneNumber {
        width: 0px;
        float: left;
        background-color: red;
        height: 2.25rem;
        text-align: center;
        vertical-align: middle;
        margin-left: 0px;
        border-radius: 7px 0px 0px 7px;
        transition: 1s;

        svg {
            display: none;
            vertical-align: middle;
            font-size: 1.6875rem;
            color: #fff;
            margin: 4px;
        }
    }

    .PhoneInput {
        input {
            padding: 0.32rem 0px;
            padding-left: 0.5rem;
        }
    }
}

.PhoneInputInput {
    &::placeholder {
        font-size: 0.75rem;
        opacity: 0.42;
    }
}