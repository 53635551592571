@import "./Variables.scss";

.cancellationSuccessContainer {
    padding: 4rem 0;

    h3 {
        font-size: 1.125rem;
        font-weight: 700;
        padding-bottom: 0.50rem;
    }

    span,
    p {
        font-size: 1rem;
        padding-bottom: 0.250rem;
    }
}