@import "./Variables";

.timerContainer {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    color: #ffff;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 10px;

    svg {
        font-size: 18px;
    }
}

.timerCont {
    width: 100%;
    max-width: 690px;
    margin: 0px auto;
    position: relative;
}

@media (max-width: 767px) {
    .timerContainer {
        width: 100%;
        height: 10px;
        padding: 10px 0;
        border-radius: 0;
        font-size: 12px;
        left: 0;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            font-size: 16px;
            margin-right: 5px;
        }
    }
}