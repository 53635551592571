.paymentPackagePageContainer {
    position: relative;

    .actionContainer {
        display: flex;
        justify-content: center;

        .MuiButton-root {
            margin: 5px 15px;
        }
    }

    .noFix {
        position: absolute !important;
        bottom: 5px;
        transform: translateY(100%);
        left: 0;
        font-size: 1.3rem;
        box-shadow: none;
        text-transform: inherit;
        height: 70px;

        .MuiSvgIcon-root {
            font-size: 1.3rem;
        }
    }

    .successTitle {
        font-size: 1.125rem !important;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .middleContainer {
        padding: 20px 0 10px 0;
    }

    .successSubTitle {
        font-size: 1rem !important;
        font-weight: 400 !important;
        margin-bottom: 10px;
    }

    .successDescription {
        font-size: 0.75rem !important;
    }
}

.payment-package-total {
    min-height: auto;
    border: none;
    cursor: inherit;
    margin-top: 0px !important;
    padding-top: 0px !important;

    svg {
        margin-right: 5px;
        vertical-align: bottom;

        &#person {
            width: 20px;
            height: 16px;
        }
    }
}

.payment-package-total .packageName {
    margin-bottom: 0;
}

.payment-package-total .packagePrice {
    font-size: 18px;
}

.MuiGrid-root .MuiTypography-root.sectionTitle {
    margin-top: 0 !important;
}

.paymentLab {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #7F8C99;
    font-style: italic;
    padding-top: 5px;
}

.infoItem {
    display: inline-block;
    width: 137px;

    @media(max-width:767px) {
        width: auto;
    }
}

@media (max-width: 960px) {

    .paymentPackagePageContainer {
        .successTitle {
            font-size: 1.2rem !important;
        }

        .successSubTitle {
            font-size: 1.1rem !important;
        }

        .actionContainer {
            flex-direction: column;

            .MuiButton-root {
                margin: 5px 0;
            }
        }

    }

}

.padding-lr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.packageContainer {
    display: flex;
    align-items: stretch !important;
    direction: ltr;

    .packageDescription {
        display: flex;
        align-items: center;
    }

    .packageIcon {
        width: 28px;
        height: 28px;
        margin-right: 5px;
        background-color: #F3F3F3;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
            width: 25px;
            height: 15px;
        }
    }
}

.restTicketsNumber {
    color: #E01616;
    font-size: 12px !important;
}

.ticketsNumber {
    color: #E01616;
    font-size: 13px;
}

.packagesWithoutPayment {
    margin-bottom: 15px !important;
}