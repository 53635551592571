.preferred-section-parent{
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}
.section-content {
    .preferred-section {
        &.MuiGrid-root {
            padding-right: 10px;
            padding-left: 10px;
            margin-bottom: 15px;
            padding-bottom: 0px;

            @media only screen and (max-width: 600px) {
                padding: 0px;
            }
        }
        &.MuiGrid-root:first-child{
            padding-left: 0;
        }
        &.MuiGrid-root:last-child{
            padding-right: 0;
        }

        button {
            box-shadow: none;
            font-size: 0.813rem;
            padding: 7px 10px;
            border-radius: 5px;
            border: 1px solid rgb(235, 238, 244);
            height: 50px;
            text-transform: none;

            &.selected {
                border: 0;
            }

            &.Mui-disabled {
                color: rgba(0, 0, 0, 0.26) !important;
                background-color: rgba(0, 0, 0, 0.12) !important;
            }
        }

        .MuiButton-label {
            justify-content: center;
            font-weight: bold;
            word-break: break-all;
            line-height: 14px;
        }
    }
}