.carouselDate {

    padding: 8px 0px;
    text-align: center;
    background-color: #d5d5d5;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: bold;

    // width: 80px;
    &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .carouselDay {
        font-size: 14px;
        line-height: 1;

        input {
            padding-top: 0px;
        }
    }

    .carouselMonth {
        font-size: 13px;
        line-height: 1;
        text-transform: initial;
    }

    &.selected {
        background-color: #04c985;
        color: #fff;
        // box-shadow: 0px 10px 12px #e7eff6;
        // -webkit-box-shadow: 0px 10px 12px #e7eff6;
    }

    &:focus-visible {
        // outline: webkit-focus-ring-color auto 0px !important;
        border: none;
        outline: none;
    }
}

.dateSlider {
    margin-left: 30px;
    margin-right: 30px;

    // .MuiGrid-item {
    //     padding-top: 0px !important;
    //     padding-bottom: 0px !important;
    // }
}

.numberBox {
    position: relative;
    // margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;

    .numbersContainer {
        // margin-top: -4px;
    }

    .slider {
        position: inherit !important;
        // margin-top: 0px;
    }

    .carouselNumberBox {
        // margin-top: -10px;
        // margin-bottom: 0px;

        .slider {
            // margin-top: 10px;
        }
    }

    // .carouselDate {
    //     // height: 24px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }

    .numberBoxField {
        display: flex;
        height: 100%;
        border-radius: 5px;

        .carouselDay {
            padding: 0 0.9375rem;
            align-self: center;

            input {
                // height: 15px;
                padding: 0;
            }
        }
    }
}



.slider {
    // margin-top: 5px;

    .carouselRenderButtons {
        font-size: 2.8125rem;
        color: #444;
        background-color: transparent;
        display: flex;

        &.hideButton {
            display: none;
        }
    }
}

.sectionTitle {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    margin-top: 0.625rem !important;
    color: #4F4F4F;
}

.MuiGrid-root {
    hr {
        margin-top: 0.9375rem;
        margin-bottom: 0.9375rem;
    }
}

button {
    &.selected {
        background-color: #04c985;
        color: #fff;
    }
}

.starIcon {
    color: #EEC63E;
    font-size: 1.375rem;
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
}

.createReservationForm {
    background-color: #fff;
    padding: 0px 3.125rem;

    .errorMessage {
        margin-left: 45px;
        margin-right: 45px;
        margin-bottom: 8px;
        margin-top: -10px;
        font-size: 0.76rem;
    }

    @media only screen and (max-width: 767px) {
        padding: 0px 0px !important;
    }

    .inner-layout {
        width: 100%;

        @media only screen and (max-width: 767px) {
            padding: 0 1.5rem !important;
        }
    }

    .package {
        margin-top: 0px !important;
        width: 100%;

        &:first-child {
            margin-top: 15px !important;
        }
    }

    .iWannaHaveButton {
        text-transform: capitalize;
        font-weight: bold;
        color: #4F4F4F;
        padding: 0px;
        // height: 2.5rem;
        font-size: 0.7rem;
        font-size: 0.813rem;
        box-shadow: none;
        padding-top: 3px;

        &.selected {
            color: #fff;
        }

        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }

    .availableTimeSection {
        .MuiButton-label {
            text-transform: capitalize;
        }

        .Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            background-color: rgba(0, 0, 0, 0.12) !important;
        }
    }

    .SelectedMonth {
        margin-top: 0px !important;
    }

    .slider {
        .carouselDayName {
            font-size: 0.875rem;
            line-height: 1.25;
            font-weight: bold;
            text-transform: initial;
        }

        .carouselDay {
            font-size: 0.938rem;
        }

        .carouselDate {
            padding: 0.5rem 0px;
            border-radius: 5px;
        }
        &.durationsLessThanFive{
            .slider-list {
                transform: unset !important;
            }
        }

        .slider-frame {
            overflow-x: clip !important;
            overflow-y: initial !important;
            // left:3%;

            .slider-list {
                margin: 0px !important;
                // height: 4.375rem !important;
                // height: 50px !important;

                .slider-slide {
                    margin: auto 0px !important;

                    &:focus-visible {
                        outline: unset;
                    }
                }
            }
        }

        .slider-control-centerleft {
            left: -2.1875rem !important;
        }

        .slider-control-centerright {
            right: -2.1875rem !important;
        }
    }

    .carouselNumberBox {
        .slider {
            .slider-frame {
                .slider-list {
                    // height: 38px !important;
                }

                .slider-control-centerleft,
                .slider-control-centerright {
                    display: flex;
                }
            }
        }
    }
}

.eventCarouselContainer {
    padding: 0.625rem 3.125rem;

    // .slider-list {
    //     height: 9.5625rem !important;
    // }

    &.eventCarouselOnTop {
        margin-bottom: 0px !important;
        margin-top: 0 !important;
        background-color: transparent;

        .selectLanguage {
            margin-bottom: 0.9375rem !important;
            margin-left: 0px !important;
            margin-top: 0px !important;
        }
    }

    .slider-control-centerleft {
        left: -2.1875rem !important;
    }

    .slider-control-centerright {
        right: -2.1875rem !important;
    }
}

.numberBox {
    .carouselDay {
        font-weight: bold;
    }
}

.carouselNumberBox {
    // height: 50px;

    .numberBoxSquare {
        width: auto;
    }

    .slider-list {
        // height: auto !important;
    }
}

.numberBoxField {
    .carouselDay {
        padding: 0.084375rem 0.9375rem;
        text-align: center;
        line-height: 1px;


        @media only screen and (max-width: 900px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        button{
            width: 25px;
            height: 25px;
            svg{
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }
        }
        }
    }

    .MuiInput-underline {
        &:before {
            border-bottom: 0px;
        }
    }

    .MuiInputBase-root {
        font-family: inherit;
    }

    input {
        font-size: 0.938rem;
        padding-bottom: 0px !important;
        text-align: center;
        font-weight: bold;
        font-family: inherit;

        &::placeholder {
            font-weight: normal !important;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}

.react-datepicker,
.react-datepicker__month-container {
    width: 100%;
    border: 0px !important;

    .react-datepicker__day--selected {
        background: #05c985 !important;
        color: #fff !important;
        font-weight: bold;
    }

    .react-datepicker__day {
        &.react-datepicker__day--keyboard-selected {
            background: none;
        }
    }

    .react-datepicker__header {
        background-color: transparent;
        border-bottom: 0px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        margin: 0.3125rem 4%;
        padding: 3px;
        border-radius: 50%;
        color: #4F4F4F;

        &:hover {
            background-color: #04c985;
            border-radius: 50%;
            color: #fff;
        }
    }

    .react-datepicker__day--keyboard-selected {
        background-color: #04c985;
        color: #fff;
        font-weight: bold;
    }

    .react-datepicker__day--disabled {
        color: #e2e2e2;
    }
}

@media only screen and (max-width: 360px) {
    .createReservationForm {
        padding: 0 2.1875rem;
    }

    .react-datepicker,
    .react-datepicker__month-container {

        .react-datepicker__day-name,
        .react-datepicker__day {
            padding: 1px;
        }
    }

    .allergiesForm {
        .MuiInputLabel-formControl {
            font-size: 0.9375rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .createReservationForm {
        .errorMessage{
            width: auto;
        }
    }
}
@media only screen and (max-width: 500px) {
    .numberBox {
        .numberBoxSquare {
            width: 22% !important;
            margin-bottom: 0;
        }

        .numberBoxFieldSquare {
            // margin-top: 0.625rem;
            width: 22%;

            input {
                &::placeholder {
                    font-size: 0.75rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .numberBoxSquare {
        width: 12% !important;
        margin-bottom: 0;
        margin-top: 0.625rem;
    }

    .carouselNumberBox {
        .numberBoxSquare {
            width: 100% !important;
            margin: 0 !important;
        }
    }

    .sectionTitle {
        font-size: 0.875rem !important;
    }

    .allergiesForm {

        .MuiTextField-root,
        .sectionTitle {
            margin-bottom: 0.3125rem;
        }

        .smallHint {
            float: none !important;
            display: block;
        }
    }
}

@media only screen and (max-width: 750px) {

    .react-datepicker,
    .react-datepicker__month-container {

        .react-datepicker__day-name,
        .react-datepicker__day {
            margin: 0.3125rem 0px;
            width: 11.5%;
        }
    }

    .viewEvent {
        .eventViewLogoContainer {
            margin-top: -47px;
        }
    }
}

.chooseVenueDropdownContainer {
    .chooseVenueDropdown {
        .MuiInput-underline {

            &::after,
            &::before {
                display: none;
            }
        }

        label {
            left: 1.25rem;
            width: 100%;
        }
    }
}

.numberBoxSquare {
    width: 9.3%;
    line-height: 1.45;
    padding: 0 0.25rem;
}

.numberBoxFieldSquare {
    // width: 24%;
    line-height: 1.45;
    padding-left: 0.25rem;
}

.selectLanguage {
    float: left;
    margin: 0.625rem 1.875rem !important;
    width: 93px;
    max-width: 93px;

    .MuiSelect-select {
        // text-transform: uppercase;
        font-weight: bold;
        font-size: 0.813rem;
        height: 30px;
        padding-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .MuiInput-underline {
        &:before {
            border-bottom: 0px !important;
        }
    }
}

.MuiSelect-select {
    &:focus {
        background-color: transparent !important;
    }
}

.headerBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.875rem;

    .selectLanguage {
        background-color: white;
        border: 1px solid #CCC;
        border-radius: 3px;
        // padding: 0 6px 0 8px;
        font-size: 14px;
        display: block;
        height: 30px;

        .MuiSelect-select {
            padding-top: 7px;
            padding-bottom: 10px;
            height: 0px;
        }

        svg {
            right: -5px;
        }
    }

    .MuiInput-underline {
        &:after {
            display: none;
        }
    }
}

.headerBlockEmpty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.875rem;
    height: 30px;
    margin: 0.625rem 1.875rem !important;
}

.preferredSectionRadioContainer {
    text-align: left;
    float: left;

    .MuiFormControlLabel-label {
        font-family: inherit;
        font-size: 0.813rem;
    }

    .Mui-focused {
        color: rgba(0, 0, 0, 0.54) !important;
    }

    legend {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
    }
}

.capacityErrorMessage {
    margin-bottom: 10px !important;

    .errorMessage {
        font-size: 1.0625rem;
        float: none;
    }
}

.apiError {
    width: 50%;
    text-align: center;
    border: 1px solid red;
    border-radius: 5px;
    position: absolute;
    top: 20%;
    z-index: 10;
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
}

.placeholder {
    font-size: 0.75rem;
    opacity: 0.42;
    //p{
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    //}
}

.allergiesForm {
    h5 {
        font-size: 14px !important;
        font-weight: 400 !important;
        margin: 0 0 -4px 0 !important;
    }

    label,
    .MuiChip-label {
        font-size: 0.8rem;
    }

    input {
        font-size: 1rem;
    }

    .smallHint {
        font-style: italic;
        float: right;
    }

    .MuiChip-label {
        text-overflow: initial;
    }
}

.MuiDivider-root {
    margin: 12px 0 !important;
    margin-top: 15px !important;
}

.makeReservation {
    .margin-b-5 {
        margin-bottom: 5px;
    }
}

.package {
    width: 100%;
}

.tableFor {
    padding-top: 15px;
}

.numbersContainer {
    margin: 0 -4px;
    width: calc(100% + 8px) !important;
    justify-content: flex-start;

    .BoxItem {
        width: calc(14.5% - 8px);
        margin: 4px;
    }

    .numberBoxFieldSquare {
        margin-top: 0;
        width: 12%;

        @media only screen and (max-width: 599px) {
            width: 50%;
        }
    }
}

@media only screen and (max-width: 599px) {
    .numbersContainer {
        padding-top: 10px;

        .BoxItem {
            width: calc(20% - 8px);
        }
    }

    .numberBox {
        .carouselNumberBox {
            margin-top: 0px;
        }
    }

    .buttonFix {
        span {
            font-size: 1.15rem !important;
            line-height: 25px;
        }
    }

    .chooseVenueDropdown {
        .MuiOutlinedInput-root {
            width: 50%;
        }
    }

    .venueDropdownLabel {
        width: 45% !important;
    }

    .MuiMenu-paper {
        li {
            min-height: 30px;
            font-size: 0.9rem;
        }
    }

    .MuiSelect-select {
        option {
            padding-left: 8px;
        }
    }

    .allergiesForm {
        .MuiGrid-item:not(:first-child) {
            // margin-bottom: 8px;
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 500) {
    .numbersContainer {
        .BoxItem {
            width: calc(25% - 8px);
        }
    }
}

.totalAmount {
    position: absolute;
    right: 50px;
    font-weight: 600;
    font-size: 18px;
}

@media only screen and (max-width: 599px) {
    .buttonFix {
        width: calc(100% + 70px);

        .totalAmount {
            font-size: 14px !important;
        }
    }
}

.form-space {
    margin-bottom: 8px !important;
}

.padding-t-0 {
    padding-top: 0px;
}

.block-heading {
    padding-bottom: 11px !important;
}

.section-content {
    // padding-top: 6px;
    // padding-bottom: 1px;
    // justify-content: center;
    position: relative;
    padding: 0px 45px;
}

.venueCloseContainer {
    font-size: 16px;
    padding: 50px 25px 30px;
    color: #2A3F54;
    line-height: 25px;
}

.eventPostSlider {
    .slider {
        .slider-frame {
            // .slider-list {
            //     height: 8.5rem !important;
            // }
        }
    }
}

.MuiGrid-root {
    .MuiTypography-root {
        &.sectionTitle {
            &.margin-b-0 {
                margin-bottom: 0px !important;
            }
        }
    }
}

.guestSlider {
    padding-bottom: 10px;
}

.MuiInput-underline:after {
    border-bottom: 0px !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px !important;
}

.widgetDefaultButton {
    width: 80px !important;
    height: 50px !important;
    border: 1px solid transparent;
    border-radius: 5px;
    opacity: 1;
}

.nextButtonCont {
    margin-top: 20px !important;
}

.eventIconContainer {
    display: flex;
    align-items: center;
    float: left;
    cursor: pointer;
    position: absolute;
    right: 5px;
}

.eventDetailsHeader {
    text-align: left;
    padding-top: 6px;
    padding-left: 0px;

    .eventName {
        font-size: 14px;
        font-weight: bold !important;
        color: #2A3F54;
        text-transform: uppercase;
        margin-top: 5px;
    }

    .eventDate {
        font-size: 14px;
        opacity: 0.7;
        color: #2A3F54;
        padding-top: 0px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 767px) {

    .datepickercont {
        flex-basis: 90% !important;
        max-width: 90% !important;
        margin: auto !important;
        position: relative;
    }

    .createReservationForm {
        .MuiDivider-root {
            width: 90%;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}

.availableEventsTitle {
    padding-bottom: 4px;
}

.eventPadding {
    .eventNumberGuest {
        .inner-layout {
            @media (max-width: 767px) {
                padding: 0px 10px !important;
            }
        }
    }
}

.joinListTitle {
    font-weight: normal;
    padding-top: 12px !important;
    
    @media (min-width: 960px) {
        padding-left: 14px !important;
        padding-right: 10px !important;
    }
    div {
        color: #2A3F54 !important;
        
        @media (max-width: 959px) {
            text-align: center !important;
        }
        @media (max-width: 767px) {
            font-size: 12px !important;
        }
    }
}


.joinBtn {
    @media (min-width: 960px) {
        padding-right: 11px !important;
    }
}
.durationContainer{
    .available-time{
        span{
            text-transform: lowercase;
        }
    }
}