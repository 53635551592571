.successPageContainer,
.failedPageContainer {
    padding-bottom: 5px;
    position: relative;

    .actionContainer {
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-right: -15px;
        margin-left: -15px;

        .MuiButton-root {
            margin: 5px 15px;
        }
    }

    .actionContainer.actionContainerButton {
        margin-bottom: 15px;
    }

    &.border-radius-0 {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .noFix {
        position: absolute !important;
        bottom: 5px;
        transform: translateY(100%);
        left: 0;
        font-size: 1.3rem;
        box-shadow: none;
        text-transform: inherit;
        height: 70px;
        width: 100%;

        .MuiSvgIcon-root {
            font-size: 1.3rem;
        }
    }

    .successTitle {
        font-size: 1.125rem !important;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .middleContainer {
        padding: 20px 0 0 0;

        &.failedMiddleContainer {
            max-width: 450px;
            margin: 0 auto;
        }
    }

    .successSubTitle {
        font-size: 1rem !important;
        font-weight: 400 !important;
        margin-bottom: 10px;
    }

    .successDescription {
        font-size: 0.75rem !important;
    }

    .failedPageTitle {
        max-width: 450px;
        margin: 0 auto 15px auto;
    }
}

@media (max-width: 960px) {

    .successPageContainer,
    .failedPageContainer {
        .successTitle {
            font-size: 1.2rem !important;
        }

        .successSubTitle {
            font-size: 1.1rem !important;
        }

        .actionContainer {
            flex-direction: column;

            .MuiButton-root {
                margin: 5px 0;
            }
        }

    }
}