html {
    font-size: 16px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.note {
    color: #444;
    text-align: center;
    padding: 0.9375rem;
    font-size: 0.875rem !important;
}

.noVerticalPadding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

body .MuiButton-containedPrimary {
    background-color: #00A0E1;
}

.errorMessage {
    color: #ff0000;
    float: left;
    font-size: 0.7rem;
    text-align: left;
    font-style: italic;
}
.mainError{
    font-size: 20px;
    text-align: center;
    width: 100%;
}
.smallError {
    width: 100%;
    color: #2A3F54;
    text-align: center;
    font-size: 14px !important;
    font-style: italic;
    opacity: 0.72;
    max-width: 460px;
    margin: 20px auto;
    float: none;
    display: inline-block;
    margin-bottom: 0px !important;
    margin-top: 20px;
    padding-top: 12px;
}

.successMessage {
    color: #3ce608;
    float: left;
    margin-top: 15px;
    font-size: 14px;
}

.buttonFix {
    /* position: fixed !important;
    bottom: 0px; */
    border-radius: 0px !important;
    padding: 0px !important;
    height: 50px;
    z-index: 2;
    max-width: 690px;
    width: 690px;
    font-size: 1.16rem !important;
    left: 50%;
    transform: translateX(-50%);
    text-transform: inherit !important;
}

.buttonFix .MuiSvgIcon-root {
    font-size: 1.3rem;
}

.nextButton {
    border-radius: 0px !important;
    padding: 0px !important;
    height: 70px;
    max-width: 100%;
    width: 100%;
    font-size: 1.3rem !important;
    left: 50%;
    transform: translateX(-50%);
    text-transform: inherit !important;
}

.nextButton .MuiSvgIcon-root {
    font-size: 1.3rem;
}

body .nextButtonOnPage {
	margin: 0px -50px;
	width: calc(100% + 92px);
	display: block !important;
	flex-basis: auto;
	flex-grow: initial;
	max-width: inherit;
	box-sizing: border-box;
	padding: 0 !important;
}

/* .buttonFix:hover {
    background-color: #00A0E1 !important;
} */

.overlay {
    color: '#00A0E1' !important;
    z-index: 10 !important;
}

.appRoot {
    background-color: #e5e5e5;
    min-height: 100vh;
}

.BackgroundOverlay {
    min-height: 100vh;
}

.copyright {
    color: #95A0AA;
    font-size: 0.875rem;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 8px;
    margin: 10px 0px;
    direction: ltr !important;
    /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
    width: 100%;
}

.copyright a {
    font-size: 0.875rem;
    color: #333;
    text-decoration: unset;
}

.copyright {
    font-size: 0.8rem !important;
}

.copyright img {
    width: 57px;
}
.closedVenueError{
    font-size: 20px;
    margin: auto !important;
    width: 100%;
    text-align: center;
}
.closedVenueError h1{
    font-size: 24px;
    font-weight: 600;
}
.padding-b-0{
    padding-bottom: 0px !important;
}
.padding-tb-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.loginContainer form .MuiFormControl-root ::placeholder {
    text-transform: none !important;
    font-size: 0.75rem;
    opacity: 0.42;
}

.loginContainer form .MuiOutlinedInput-multiline {
    padding: 0;
}


@media (max-width:959px){
body .payment-package-total {
	flex-grow: 0;
	max-width: 83.333333%;
	flex-basis: 83.333333%;
    padding-bottom: 2px !important;
}

}

@media (max-width:767px){
    .appRoot {
        overflow: hidden;
    }
    .buttonFix {
        position: relative;
        width: calc(100% + 40px);
    }
    .buttonFix::after, .buttonFix::before  {
        position: absolute;
        height: 100%;
        content: "";
        width: 100%;
      }

      .buttonFix::after {
        right: -100%;
      }
      .buttonFix::before {
        left: -100%;
      }
}