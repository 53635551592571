@import "./Variables.scss";

.cancellationReasonContainer {
    text-align: left;

    span {
        text-align: left;
    }

    .MuiFormGroup-root {
        margin-top: 20px;
        margin-bottom: 35px;

        .MuiFormControlLabel-label {
            font-size: 14px;
        }

        .otherReason {
            align-items: flex-start;

            .MuiFormControlLabel-label {
                width: 100%;

                .formText {
                    margin-top: 13px;
                }
            }
        }

        .MuiRadio-root {
            color: $grey;
            padding: 7px;
            margin-right: 3px;

            .MuiIconButton-label {
                &>div {
                    .MuiSvgIcon-root {
                        &:nth-child(2) {
                            color: $white;
                            font-size: 18.5px;
                        }
                    }
                }
            }
        }

        .MuiRadio-colorSecondary.Mui-checked {
            color: $red;

            &::before {
                width: 16px;
                height: 16px;
                background: $red;
                content: "";
                position: absolute;
                border-radius: 50%;
            }
        }

        .MuiSvgIcon-root {
            font-size: 19px;
        }

        .MuiInputBase-input {
            opacity: 1;

            &::placeholder {
                color: $darkGrey;
                opacity: 1;
            }
        }

        .MuiOutlinedInput-input {
            padding: 15px;
            font-size: 14px;
        }
    }
}