@import "./Variables.scss";

.paymentContainer {
    padding-bottom: 30px;

    .paymentMethodContainer {
        color: #8e8b8b;
        margin-top: 25px;
        width: 100%;

        .radioPaymentLabel {
            font-weight: bold;
            text-align: left;
            color: #000;
        }

        .MuiFormControlLabel-root {
            margin-right: 50px;
        }
    }

    .sectionTitle {
        font-size: 1rem;
        font-weight: bold;
    }

    hr {
        margin: 25px 0px;
    }

    .orderName {
        text-align: left;
    }

    .orderPrice {
        text-align: right;
    }

    .promocode {
        margin: 10px 0px;

        .MuiOutlinedInput-input {
            padding-top: 13px;
            padding-bottom: 13px;
        }

        button {
            position: absolute;
            margin-left: -75px;
            margin-top: 5px;
            font-weight: bold;
            color: $button-background-color-primary;
        }

        svg {
            color: #999;
            cursor: pointer;
            margin-top: 20px;
        }
    }

    .promocodeActive {
        font-weight: bold;
        color: #ff0000;

        svg {
            color: #999;
            cursor: pointer;
            font-size: 21px;
            margin-left: 15px;
        }
    }

    .total {
        font-size: 18px;
        font-weight: bold;
        margin: 30px 0px 5px;
    }

    .inlineLabel {
        margin-top: 30px;
        font-weight: bold;
        text-align: left;
        color: #444;
    }

    .cancellationPolicy {
        text-align: left;
        color: #444;
    }
}