.mainTitle {
    &.MuiTypography-root {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    &.widgetTitle {
        padding: 0 20px;
    }
}
.chooseVenueDropdown .multipleVenuesSelection{
    // margin-left: auto !important;
    // margin-right: auto !important;
    // float: none !important;
}
@media only screen and (min-width: 900px) {
    .chooseVenueDropdownContainer{
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }
.chooseVenueDropdown {
    .venueDropdownLabel {
        float: left;
        width: auto !important;
        text-align: right;
        padding-top: 8px;
    }
    .MuiOutlinedInput-root{
            width: 200px;
            float: left;
            margin-left: 4rem;
        }
    }
}