@import "./Variables.scss";

.loginContainer {
    height: auto;
    background-color: $background-color;
    color: #000;
    margin-bottom: 4.7rem;

    .note {
        padding-top: 0;
        padding-left: 1.4375rem;
        padding-right: 1.4375rem;

        &.noteHeading {
            padding: 0;
            text-align: left;
            margin: 4px 0 -4px 0;
            line-height: 16px;
        }
    }

    .MuiOutlinedInput-input {
        padding: 0.84375rem 0.875rem;

        &.MuiOutlinedInput-inputAdornedStart {
            padding-left: 0px;
        }
    }

    .PrivateSwitchBase-root-5 {
        padding: 0.3125rem 0.5625rem;
    }

    .noVerticalPadding {
        .checkbox {
            align-items: center;

            @media (max-width:767px) {
                align-items: flex-start;
            }
        }
    }

    form {
        .MuiFormControl-root {
            ::placeholder {
                text-transform: capitalize;
            }

            svg {
                color: $field-icon-color;
            }
        }

        .Mui-error {
            ::placeholder {
                color: red;
            }

            svg {
                color: red;
            }
        }

        .phoneNumberError {
            ::placeholder {
                color: red;
            }
        }

        .phoneNumberCodeLabel {
            display: none !important;
        }

        .checkbox {
            float: left;
            color: $checkbox-label-color;

            span {
                font-size: 0.75rem;
                text-align: left;
            }
        }

        .typographyNote {
            color: #888;

            span {
                padding-bottom: 2px;

                @media (max-width:767px) {
                    line-height: 16px;
                }
            }

            hr {
                margin: 0.9375rem 0px;
                margin-bottom: 5px !important;
            }
        }

        .notes-divider {
            padding-top: 0px;
            padding-bottom: 12px;

            hr {
                @media (max-width:767px) {
                    margin-top: 5px !important;
                }
            }
        }
    }

    // .copyright {
    //     margin-top: 0px;
    //     padding-top: 25px;
    // }

    .MuiButton-containedPrimary {
        // margin: 0.9375rem 0px;
        width: 100%;
    }

    .MuiCheckbox-colorPrimary {
        svg {
            color: #00A0E1;
        }
    }
}

.loginFormContainer {
    .sectionTable {
        align-items: inherit;
    }
}

.datepicker {
    background-color: white;
    border-radius: 5px;

    button {
        padding: 0px !important;
    }
}

.youAreBookingFor {
    text-align: left;
    font-size: 0.9375rem;
    margin-top: 0.3125rem;
}

.MuiOutlinedInput-adornedStart {
    font-family: inherit !important;

    input {
        font-size: 0.75rem;
    }
}

.privacyPolicyCheckbox {
    margin-bottom: 1.4rem;

    &.noGap {
        margin-bottom: 0;
    }

    &.checkbox {
        span {
            margin-top: -9px;
        }

        .MuiTypography-root {
            div {
                min-height: 26px;
            }
        }
    }

    .MuiCheckbox-root {
        // padding-top: 1px;
    }

    .MuiFormControlLabel-label {
        position: relative;

        .errorMessage {
            @media (min-width: 767px) {
                position: absolute;
                right: 35px;
                /* bottom: -19px; */
                top: 30px;
            }

            @media (max-width: 767px) {
                margin-top: 0px;
            }

            font-size: 0.7rem;
            font-style: italic;
        }
    }

}

div[dir=rtl] {

    .errorMessage {
        float: right;
    }

    .MuiTypography-alignLeft {
        text-align: right;
    }

    .available-time {
        .join-wailist-btn {
            margin-right: 0px;
            margin-left: 1.5625rem;
            float: left;
        }
    }

    .createReservationForm {
        .iWannaHaveButton {
            direction: ltr;
        }
    }

    .eventContainer {
        .eventTitle {
            text-align: right;
        }

        .eventImage {
            .upcomingLabel {
                margin-left: 0px;
                margin-right: 0.625rem;
            }
        }

        .eventImageRel {
            // background-image: none !important;
            height: auto;

            img {
                width: 100%;
                display: block;
                opacity: 0;
            }
        }
    }

    .buttonFix {
        right: 50%;
        transform: translateX(50%);
    }

    .eventIconContainer {
        right: unset;
        left: 5px;
    }

    .logoContainer {
        .logo {
            margin-right: auto;
            margin-left: 1.875rem;
        }

        // .MuiGrid-root{
        //     padding-left: 1.875rem;
        // }
    }

    .viewEvent {
        .eventViewLogoContainer {
            padding-right: unset;
            left: 15px;
            right: unset;
        }
    }

    .eventDetailsHeader {
        text-align: right;
        padding-right: 20px;
    }

    .payment-package-total {

        // text-align: right;
        .packageDescription {
            // direction: rtl;
            // float: right;
            // width: 100%;
            text-align: right;
        }

        .packagePrice {
            text-align: left;

            .packageName {
                svg {
                    margin-right: 0px;
                    margin-left: 5px;
                }
            }
        }

    }

    .infoContainer {
        .title {
            text-align: right;
        }

        h6 {
            .icon {
                margin-right: 0px;
                margin-left: 8px;
            }
        }
        .reservation-item {
            margin-left: 7px;
            width: 175px;
        }
    }

    .infoItem {
        width: 143px;
        text-align: right;
    }

    .icon-list-block {
        .reservation-item {
            width: 175px;
            flex: none;
        }
    }

    .PhoneInput {
        .PhoneInputCountry {
            margin-left: 0px !important;
            padding-left: 0.1875rem;
            padding-right: 0.625rem;
        }

        .PhoneInputInput {
            direction: ltr;
            text-align: right;
        }
        .phoneInputDropdown {
            .phoneInputDropdownImg {
                float: right;
                margin-right: 0px;
                margin-left: 0.5rem;
            }
        }
    }

    .allergiesForm {
        .MuiInputLabel-root {
            transform-origin: top right;
            right: 1.75rem;
            left: auto
        }

        legend {
            text-align: right;
        }

        .MuiAutocomplete-tag {
            .MuiChip-deleteIcon {
                margin-left: 0.3125rem;
                margin-right: 0px;
            }
        }
        .MuiSelect-iconOutlined {
            right: unset;
            left: 7px;
        }
    }

    .privacyPolicyCheckbox {
        .errorMessage {
            right: 160px;
        }
    }

    .packageContainer {
        direction: rtl;

        .packageIcon {
            margin-right: 0px;
            margin-left: 5px;
        }

        .packagePrice {
            text-align: left;
        }

        .packageField {
            margin-left: 0px;
            margin-right: 7px
        }
    }

    .loginContainer {
        .MuiInputBase-formControl {
            .MuiChip-root {
                .MuiChip-deleteIcon {
                    margin: 0 -6px 0px 5px;
                }
            }
        }
        form {
            .checkbox {
                float: right;

                span {
                    text-align: right;
                }
            }
        }
    }
}

.customFormPadding {
    padding: 0px 0.5rem;

    .MuiSelect-root {
        padding: 0.296rem 0px;
    }

    .noBorder {
        .MuiFormControl-root {
            border-radius: 5px;
            background-color: #fff;
        }

        fieldset {
            border: none !important;
            box-shadow: 1px 1px 2px 1px #e9e9e9;
        }
    }
}

.chooseTimeSelect {
    .MuiSelect-root {
        padding: 0.84375rem 0.875rem;
    }
}

.chooseVenueDropdownContainer {
    padding: 5px;
}

.chooseVenueDropdown {
    padding: 4px 0;
    font-weight: 500;
    width: 100%;
    display: block !important;

    .MuiSelect-selectMenu {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .MuiOutlinedInput-root {
        width: 200px;
        float: left;

        .MuiOutlinedInput-notchedOutline {
            border-color: inherit !important;
        }
    }
}

.venueDropdownLabel {
    float: left;
    width: 245px !important;
    text-align: right;
    // margin-right: 15px;
    padding-top: 8px;
}

.date-of-birth-block {
    display: flex;
    flex-wrap: wrap;

    .MuiGrid-grid-xs-6 {
        @media (max-width: 767px) {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .birthday-half-col {
        display: flex;
        justify-content: space-between;
        padding-left: 13px;

        @media (max-width: 767px) {
            max-width: 100%;
            flex-basis: 100%;
            padding-left: 0;
            margin-top: 15px;
        }

        .MuiGrid-item {
            max-width: calc(50% - 5px);
            box-sizing: border-box;

            .chooseDateOfBirthSelect {
                width: 100%;

                .MuiOutlinedInput-input {
                    padding-top: 0.648rem;
                    padding-bottom: 0.648rem;
                }
            }
        }
    }
}

.nextButtonContainer {
    padding: 0px !important;
}

.loginContainer {
    .MuiInputBase-formControl {
        .MuiChip-root {
            height: 25px;

            .MuiChip-label {
                font-size: 0.625rem;
            }

            .MuiChip-deleteIcon {
                height: 15px;
                width: 15px;
            }
        }
    }
}

.captchaContainer {
    float: right;
    padding-bottom: 20px !important;
}

.loginCopyright {
    padding: 0px !important;
}